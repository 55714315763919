import React, { useEffect, useState, useRef } from 'react';
import transition from '../transition';
import Navigation from '../components/navigation'
import '../styles/projects.css'
import slika from '../images/webshop.png';
import slika1 from '../images/barbershop.png';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { MdOutlineOpenInNew } from "react-icons/md";
import { IconContext } from "react-icons";

const Projects = () => {

    const [isScrollingDown, setIsScrollingDown] = useState(false);
    const [currentSection, setCurrentSection] = useState(1);

    useEffect(() => {
        function handleWheel(event) {
            const deltaY = event.deltaY;
            if (deltaY > 0) {
                if (currentSection < 2) {
                    setCurrentSection(currentSection + 1);
                }
            } else {
                if (currentSection > 1) {
                    setCurrentSection(currentSection - 1);
                }
            }
            setIsScrollingDown(deltaY > 0);
        }

        window.addEventListener('wheel', handleWheel);
        return () => {
            window.removeEventListener('wheel', handleWheel);
        };
    }, [currentSection]);

    const navigate = useNavigate();
    const buttonRef = useRef(null);

    const handleWheelDown = () => { 
        navigate('/contact');
    }

    const handleWheelUp = () => {
        navigate('/about');
    }

    const handleWheel = (e) => {
        if (e.deltaY < 0 && currentSection === 1) {
            handleWheelUp();
        } else if (e.deltaY > 0 && currentSection === 2) {
            handleWheelDown();
        }
    }
    

    const [showText, setShowText] = useState(false)

    const onClickDescription = () => {
        setShowText(prevShowText => !prevShowText)
    }

    const handleTouchStart = (e) => {
        const touchY = e.touches[0].clientY;
        const windowHeight = window.innerHeight;
        
        if (touchY < windowHeight / 3) {
            if (currentSection > 1) {
                setCurrentSection(currentSection - 1);
            }
            if(currentSection === 1) {
                handleWheelUp();
            }
        } else if (touchY > (windowHeight /3)*2) {
            if (currentSection < 2) {
                setCurrentSection(currentSection + 1);
            }
            if (currentSection === 2){
                handleWheelDown();
            }
        }
    }

    return (
        <div className='projects-container' ref={buttonRef} onWheel={handleWheel} onTouchStart={handleTouchStart}>
            <Navigation />
            <h1 className='about-headline'>Projects</h1>
            <motion.div
                className={`project ${currentSection === 1 ? '' : 'hidden'}`}
                initial={{ opacity: 1, x: 0 }}
                animate={{ opacity: currentSection === 1 ? 1 : 0, x: currentSection === 1 ? 0 : 800 }}
                transition={{ duration: 1 }}
                style={{ zIndex: 2 }}
            >
                <img src={slika} alt='slika' className='project-image' onClick={onClickDescription}/>
                <button className='project-button' onClick={onClickDescription}>See more</button>
                {showText ?
                    <div className='more-container'  onClick={onClickDescription}>
                        <div className='description-container'>
                        <a href='https://web-shop-snowy.vercel.app'><button className='project-button'>
                            <IconContext.Provider value={{color: "white", size: "50px"}}>
                            <MdOutlineOpenInNew />
          </IconContext.Provider>
                            </button></a>
                            <h2 className='description-headline'>Description</h2>
                            <p className='description-text'>Webshop created as a project for college. It is built using React. The webshop consists of several pages, including main page, menu, shirt menu, hoodie menu, jersey menu, shirt customization and cart. It's fully functional and ready-to-use.</p>
                        </div>
                        <div className='description-container'>
                            <h2 className='description-headline'>Tech used</h2>
                            <p className='description-text'>HTML, Css, React, Figma, React router, sass, react-slick, react-toastyfy, firebase</p>
                        </div>
                    </div>
                    : null}
            </motion.div>
            <motion.div
                className={`project ${currentSection === 2 ? '' : 'hidden'}`}
                initial={{ opacity: 0, x: 0 }}
                animate={{ opacity: currentSection === 2 ? 1 : 0, x: currentSection === 2 ? 0 : 400 }}
                transition={{ duration: 1 }}
                style={{ zIndex: 1 }}
            >
                <img src={slika1} alt='slika' className='project-image'/>
                <button className='project-button' onClick={onClickDescription}>See more</button>
                {showText ?
                    <div className='more-container' onClick={onClickDescription}>
                        <div className='description-container'>
                        <a href='https://barbershop-alpha-eosin.vercel.app'>
                        <button className='project-button'>
                            <IconContext.Provider value={{color: "white", size: "50px"}}>
                            <MdOutlineOpenInNew />
          </IconContext.Provider>
                            </button>
                            </a>
                            <h2 className='description-headline'>Description</h2>
                            <p className='description-text'>A webpage about an barbershop. It is built using React. The page consists of several sections, including main, about, team, services and contact sections.</p>
                        </div>
                        <div className='description-container'>
                            <h2 className='description-headline'>Tech used</h2>
                            <p className='description-text'>HTML, Css, React, Figma, React router, sass, react-scroll, react-cardflip, firebase, react-count</p>
                        </div>
                    </div>

                    : null}
            </motion.div>
        </div>
    )
}

export default transition(Projects)