import React from 'react'
import Menu from './menu.js'
import { CiLinkedin } from "react-icons/ci";
import { FaGithub } from "react-icons/fa";
import '../styles/navigation.css'
import { IconContext } from "react-icons";

const Navigation = () => {



    return (
        <>
        <Menu />
            <nav>
                <div className='bottom-nav'>
                    <IconContext.Provider value={{ color: "white", className: "global-class-name", size: "50px" }}>
                        <a href='https://www.linkedin.com/in/ivan-belavić'><CiLinkedin /></a>
                        <a href='https://github.com/IvanB1120'><FaGithub /></a>
                    </IconContext.Provider>
                </div>
            </nav>
        </>

    )
}

export default Navigation