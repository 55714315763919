import './App.css';
import Main from './pages/main.js'
import About from './pages/about.js'
import Projects from './pages/projects.js'
import Contact from './pages/contact.js'
import MenuOpen from './pages/menuOpen.js';
import { Routes, Route, useLocation } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion';


function App() {

  const location = useLocation();

  return (
    <>
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          <Route index element={<Main />} />
          <Route path="/about" element={<About />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/menuOpen" element={<MenuOpen />} />
        </Routes>
      </AnimatePresence>
    </>
  );
}

export default App;
