import React, { useEffect, useState, useRef} from 'react';
import { motion } from 'framer-motion';
import '../styles/about.css';
import slika from '../images/slika.jpg';
import slika1 from '../images/education.png';
import Navigation from '../components/navigation';
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer } from 'recharts';
import Transition from '../transition';
import { useNavigate } from 'react-router-dom';


const dataPrograming = [
    {
        subject: 'HTML',
        A: 150,
        fullMark: 150,
    },
    {
        subject: 'CSS',
        A: 140,
        fullMark: 150,
    },
    {
        subject: 'Git',
        A: 90,
        fullMark: 150,
    },
    {
        subject: 'React',
        A: 150,
        fullMark: 150,
    },
    {
        subject: 'Javascript',
        A: 120,
        fullMark: 150,
    },
    {
        subject: 'Typescript',
        A: 80,
        fullMark: 150,
    },
    {
        subject: 'Nodejs',
        A: 95,
        fullMark: 150,
    },
    {
        subject: 'SQL',
        A: 80,
        fullMark: 150,
    },
];

const dataDesign = [
    {
        subject: 'Framer motion',
        A: 110,
        fullMark: 150,
    },
    {
        subject: 'Photoshop',
        A: 100,
        fullMark: 150,
    },
    {
        subject: 'Figma',
        A: 140,
        fullMark: 150,
    },
    {
        subject: 'Graphic Design',
        A: 120,
        fullMark: 150,
    },
    {
        subject: 'Creativity',
        A: 130,
        fullMark: 150,
    },
    {
        subject: 'Canva',
        A: 130,
        fullMark: 150,
    },
]

const About = () => {

    const [isScrollingDown, setIsScrollingDown] = useState(false);
    const [currentSection, setCurrentSection] = useState(1);

    useEffect(() => {
        function handleWheel(event) {
            const deltaY = event.deltaY;
            if (deltaY > 0) {
                if (currentSection < 3) {
                    setCurrentSection(currentSection + 1);
                }
            } else {
                if (currentSection > 1) {
                    setCurrentSection(currentSection - 1);
                }
            }
            setIsScrollingDown(deltaY > 0);
        }

        window.addEventListener('wheel', handleWheel);
        return () => {
            window.removeEventListener('wheel', handleWheel);
        };
    }, [currentSection]);

    useEffect(() => {
        addHeadline();
    }, [currentSection]);

    const navigate = useNavigate();
    const buttonRef = useRef(null);

    const handleWheelDown = () => { 
        navigate('/projects');
}

const handleWheelUp = () => { 
        navigate('/');
}

const handleWheel = (e) => {
    if (e.deltaY < 0 && currentSection === 1) { 
        handleWheelUp();
    } else if (e.deltaY > 0 && currentSection === 3) {
        handleWheelDown();
    }
}

const addHeadline = () => {
    const newText = currentSection === 2 ? " /education" : currentSection === 3 ? " /skills" : "";
    const headlineElement = document.querySelector('.about-headline');
    if (headlineElement) {
        headlineElement.textContent = headlineElement.textContent.split('/')[0] + newText;
    }
}

const handleTouchStart = (e) => {
    const touchY = e.touches[0].clientY;
    const windowHeight = window.innerHeight;
    const sectionHeight = windowHeight / 2;
    
    if (touchY < sectionHeight) {
        if (currentSection > 1) {
            setCurrentSection(currentSection - 1);
        }
        if(currentSection === 1) {
            handleWheelUp();
        }
    } else if (touchY > sectionHeight) {
        if (currentSection < 3) {
            setCurrentSection(currentSection + 1);
        }
        if (currentSection === 3){
            handleWheelDown();
        }
    }
}

    return (
        <div className='container' ref={buttonRef} onWheel={handleWheel} onTouchStart={handleTouchStart}>
            <Navigation />
            <div className='about-container'>
                <h1 className='about-headline'>About</h1>
                <motion.div
                    className={`about-main ${currentSection === 1 ? '' : 'hidden'}`}
                    initial={{ opacity: 1, y: 0 }}
                    animate={{ opacity: currentSection === 1 ? 1 : 0, y: currentSection === 1 ? 0 : 400 }}
                    transition={{ duration: 1 }}
                >
                    <div className='about-main-left'>
                        <img className='about-image' src={slika} alt='Ivan' />
                    </div>
                    <div className='about-main-right'>
                        <div className='about-text-container'>
                            <p className='about-text'>
                                I am Ivan, a web developer with a focus on front-end technologies. I was born and currently reside in Duga
                                Resa, and I am currently pursuing a master's degree in Computer Science at the Technical University of Zagreb.
                            </p>
                        </div>
                        <div className='about-text-container'>
                            <p className='about-text'>
                                I possess knowledge of fundamental web technologies such as HTML, CSS, and JavaScript. Additionally, I have
                                experience working with React and Node.js, and I have utilized them in various projects.
                            </p>
                        </div>
                    </div>
                </motion.div>
                <motion.div
                    className={`about-main ${currentSection === 2 ? '' : 'hidden'}`}
                    initial={{ opacity: 0, x: 0 }}
                    animate={{ opacity: currentSection === 2 ? 1 : 0, x: currentSection === 2 ? 0 : 400 }}
                    transition={{ duration: 1 }}
                >
                    <div className='about-main-left'>
                        <img className='about-image' src={slika1} alt='Ivan' />
                    </div>
                    <div className='about-main-right'>
                        <div className='about-text-container'>
                            <div className='about-text'>
                                <p className='about-text-headline'>Professional Bachelor of Information Science - bacc. inf.</p>
                                    <p>Veleučilište u Rijeci [2018 - 2022]</p>
                                <p className='about-website'>Website: https://www.veleri.hr/hr</p>
                            </div>
                        </div>
                        <div className='about-text-container'>
                            <div className='about-text'>
                                <p className='about-text-headline'>Master of Engineering in Information Technology </p>
                                    <p>Tehničko veleučilište u Zagrebu [20222 - Current]</p>
                                <p className='about-website'>Website: https://www.tvz.hr</p>
                            </div>
                        </div>
                    </div>
                </motion.div>
                <motion.div
                    className={`about-main1 ${currentSection === 3 ? '' : 'hidden'}`}
                    initial={{ opacity: 0, x: 0 }}
                    animate={{ opacity: currentSection === 3 ? 1 : 0, x: currentSection === 3 ? 0 : 400 }}
                    transition={{ duration: 1 }}
                >
                    <div className='about-main-left1'>
                            <h2 className='chart-headline'>Programming</h2>
                            <div className='responsive-chart'>
                            <ResponsiveContainer width="100%" height="100%" >
                                <RadarChart cx="50%" cy="50%" outerRadius="80%" data={dataPrograming} margin={{ top: 20, right: 20, bottom: 20, left: 20 }}>
                                    <PolarGrid />
                                    <PolarAngleAxis dataKey="subject" stroke="#76ABAE" fontSize={22} />
                                    <PolarRadiusAxis angle={0} domain={[0, 150]} display="none"/>
                                    <Radar name="Mike" dataKey="A" stroke="white" fill="#76ABAE" fillOpacity={0.95} />
                                </RadarChart>
                            </ResponsiveContainer>
                            </div>
                        </div>
                        <div className='about-main-right1'>
                            <h2 className='chart-headline'>Designing</h2>
                            <div className='responsive-chart'>
                            <ResponsiveContainer width="100%" height="100%">
                                <RadarChart cx="50%" cy="50%" outerRadius="80%" data={dataDesign} margin={{ top: 20, right: 20, bottom: 20, left: 20 }}>
                                    <PolarGrid />
                                    <PolarAngleAxis dataKey="subject" stroke="#76ABAE" fontSize={22} />
                                    <PolarRadiusAxis angle={30} domain={[0, 150]} display="none" />
                                    <Radar name="Mike" dataKey="A" stroke="white" fill="#76ABAE" fillOpacity={0.95} />
                                </RadarChart>
                            </ResponsiveContainer>
                            </div>
                        </div>
                </motion.div>
            </div>
        </div>
    );
};

export default Transition(About);
