import React, {useRef} from 'react';
import '../styles/contact.css';
import Navigation from '../components/navigation';
import transition from '../transition';
import { MdOutlineMail } from "react-icons/md";
import { IconContext } from "react-icons";
import { useNavigate } from 'react-router-dom';
import slika from '../images/slika1.png';
import emailjs from '@emailjs/browser';

const Contact = () => {

  const navigate = useNavigate();
    const buttonRef = useRef(null);

  const handleWheelUp = (e) => { 
    if (e.deltaY < 0 ) { 
      navigate('/projects');
  }
}

const sendEmail = (e) => {
  e.preventDefault();
  alert("Submited");

  emailjs.sendForm('service_hzw0hi5', 'template_6g9954s', e.target, 'ubGY3AvDLrXptD6mC');
}

const handleKeyPress = (e) => {
  if (e.key === 'Enter') {
      e.preventDefault();
  }
};

const handleTouchStart = (e) => {
  if (e.touches[0].clientY < window.innerHeight / 2) {
      navigate('/projects');
  }
}

  return (
    <div className='container' ref={buttonRef} onWheel={handleWheelUp} onTouchStart={handleTouchStart}>
      <Navigation />
      <div className='contact-container'>
      <h1 className='about-headline'>Contact</h1>
        <div className='contact-left'>
          <img src={slika} alt='slika' className='contact-image' />
          <div className='contact-left-text'>
          <IconContext.Provider value={{color: "white", size: "70px"}} className="a">
          <MdOutlineMail />
          </IconContext.Provider>
          <div className='contact-flex'>
          <p className='contact-text'>Mail me at</p>
          <p className='contact-email'>ivan.belavic10@gmail.com</p>
          </div>
          </div>
        </div>
        <div className='contact-right'>
        <form className="contact-form" onSubmit={sendEmail}>
                <h2 className="form-headline">Send me a message</h2>
                <label>
                    <input type="text" name="name" placeholder="Full name" pattern="[A-Za-z]+" onKeyPress={handleKeyPress}/>
                </label>
                <label>
                    <input type="email" name="email" placeholder="Email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" title="Please enter a valid email address" onKeyPress={handleKeyPress}/>
                </label>
                <label>
                    <input type="text" name="subject" placeholder="Subject" onKeyPress={handleKeyPress}/>
                </label>
                <label>
                    <input type="textarea" name="message" className='form-text' onKeyPress={handleKeyPress}/>
                </label>
                <input type="submit" value="→" className="contact-button"/>
                </form>
        </div>
      </div>
      <div className='footer'>Copyright © 2024. Ivan Belavić</div>
    </div>
  );
};

export default transition(Contact);
