import React from 'react'
import '../styles/menuOpen.css'
import { Link } from 'react-router-dom'
import Transition from '../transition';

const MenuOpen = () => {
  return (
    <>
      <div class="openMenu-container">
        <ul>
          <li><p className='nav-text'><Link to="/" className="nav-link" >Home</Link></p></li>
          <li><p className='nav-text'><Link to="/about" className="nav-link" >About</Link></p></li>
          <li><p className='nav-text'><Link to="/projects" className="nav-link">Projects</Link></p></li>
          <li><p className='nav-text'><Link to="/contact" className="nav-link">Contact</Link></p></li>
        </ul>
      </div>
    </>
  )
}

export default Transition(MenuOpen);
