import React from 'react'
import loaderGif from '../images/loader1.gif'
import '../styles/main.css'

const Loading = () => {
  return (
    <div className='loader-container'>
        <img src={loaderGif} alt='gif' className='loader'/>
    </div>
  )
}

export default Loading