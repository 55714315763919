import React, { useRef , useState, useEffect} from 'react';
import '../styles/main.css';
import Navigation from '../components/navigation';
import Transition from '../transition.js';
import { useNavigate , useLocation} from 'react-router-dom';
import Loading from '../components/loading.js';

const Main = () => {
    const navigate = useNavigate();
    const buttonRef = useRef(null);
    const [loading, setLoading] = useState(true);
    const location = useLocation();

    const handleWheel = (e) => {
        if (e.deltaY > 0) { 
            navigate('/about');
        }
    }

    const handleClick = () => {
        navigate('/about');
    }

    const handleTouchStart = (e) => {
        if (e.touches[0].clientY > window.innerHeight / 2) {
            navigate('/about');
        }
    }

    useEffect(() => {
        const hasVisited = sessionStorage.getItem('hasVisited');
        if (!hasVisited) {
            setTimeout(() => {
                setLoading(false);
                sessionStorage.setItem('hasVisited', true);
            }, 5000);
        } else {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        if (location.action === 'POP') {
            sessionStorage.removeItem('hasVisited');
        }
    }, [location]);

    return (
        <div className='mainn' ref={buttonRef} onWheel={handleWheel} onTouchStart={handleTouchStart}>
            {loading ? (<Loading />) : 
            (
                <div className='mainn'>
                    <Navigation/>
            <div className='div1'>
                <h2 className='about-headline'>Home</h2>
            </div>
            <div className='div2'>
                <h2 className='main-headline2'>Ivan Belavić</h2>
                <h2 className='main-headline3'>Frontend developer</h2>
                <h2 className='main-headline4'>Frontend developer</h2>
                <h2 className='main-headline5'>Frontend developer</h2>
                <button className='main-button' onClick={handleClick} >↓</button>
            </div>
            </div>
            )}
        </div>
    );
};

export default Transition(Main);
