import React, { useState } from 'react';
import '../styles/menu.css'
import Transition from '../transition';
import { Link } from 'react-router-dom'
import { IoMdMenu } from "react-icons/io";
import { IconContext } from 'react-icons';

const Menu = () => {


  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
      setIsOpen(!isOpen);
  };

  return (
    <div>
        <div className="outer-menu">
        
                <Link to="/menuOpen">
                <IconContext.Provider value={{color: "white", size: "60px"}}>
                <IoMdMenu />
</IconContext.Provider>
  </Link>
    </div>
    </div>
  )
}

export default Transition(Menu);